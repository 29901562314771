import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useLayoutEffect } from 'react';

import WelcomeCardsList, { WelcomeCardListProps } from 'display-components/welcome-card-list';

// UI Kit & Components
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import useUmichSite from 'hooks/useUmichSite';

import './index.style.scss';

const GetStarted = ({ location, data }: { location: any; data: GatsbyTypes.GetStartedDataQuery }): ReactElement => {
    const { t } = useTranslation();
    const isUmichSite = useUmichSite();

    useLayoutEffect(() => {
        // For UMIch site, we are redirecting the user to mail order flow
        // TODO: make this customizable through feature flags.
        if (isUmichSite) {
            navigate('/get-started/mail-order');
        }
    });

    const welcomeCards: WelcomeCardListProps['cards'] = [
        {
            to: `/get-started/membership`,
            title: t('membership.getStarted.receiveHallway.cards.birdiPrice.title'),
            description: t('membership.getStarted.receiveHallway.cards.birdiPrice.description'),
            footNote: t('membership.getStarted.receiveHallway.cards.birdiPrice.footnote'),
            icon: `birdi-price`
        },
        {
            to: `/get-started/mail-order`,
            title: t('membership.getStarted.receiveHallway.cards.mailOrder.title'),
            description: t('membership.getStarted.receiveHallway.cards.mailOrder.description'),
            footNote: t('membership.getStarted.receiveHallway.cards.mailOrder.footnote'),
            icon: `mail-order`
        },
        {
            to: `/get-started/assist-others`,
            title: t('membership.getStarted.receiveHallway.cards.birdiCash.title'),
            description: t('membership.getStarted.receiveHallway.cards.birdiCash.description'),
            footNote: t('membership.getStarted.receiveHallway.cards.birdiCash.footnote'),
            icon: `birdi-cash`
        }
        // DRX-3279: include those items for future implementations
        // {
        //     to: `/get-started/pets`,
        //     title: t('membership.getStarted.receiveHallway.cards.pets.title'),
        //     description: t('membership.getStarted.receiveHallway.cards.pets.description'),
        //     icon: `pets`
        // }
    ];

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('registration.eyebrowText') }}
            anonymousOnly={true}
        >
            {!isUmichSite && (
                <WorkflowLayoutFormWrapper
                    className="get-started-receive-hallway"
                    currentFlow={'membership-registration'}
                    eyebrowText={t('membership.getStarted.receiveHallway.eyebrowText')}
                    title={t('membership.getStarted.receiveHallway.title')}
                >
                    <div className="get-started-receive-hallway-subtitle">
                        <p>{t('membership.getStarted.receiveHallway.subtitle')}</p>
                    </div>
                    <WelcomeCardsList cards={welcomeCards} />
                </WorkflowLayoutFormWrapper>
            )}
        </WorkflowLayout>
    );
};
export default GetStarted;

export const query = graphql`
    query GetStartedData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
